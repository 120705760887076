@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap"); */

* {
  box-sizing: border-box;
}

body {
  background: #000013;
}

@font-face {
  font-family: "BrandRegular";
  src: local("BrandRegular"),
    url(/static/media/DINNextLTPro-Regular.fd077957.ttf) format("ttf"),
    url(/static/media/DINNextLTPro-Regular.f8f4ce32.woff) format("woff");
}

@font-face {
  font-family: "BrandMedium";
  src: local("BrandMedium"), url(/static/media/DINNextLTPro-Medium.ba26a7ac.ttf) format("ttf"),
    url(/static/media/DINNextLTPro-Medium.3c3861c9.woff) format("woff");
}

@font-face {
  font-family: "BrandBold";
  src: local("BrandBold"), url(/static/media/DINNextLTPro-Bold.04d4871b.ttf) format("ttf"),
    url(/static/media/DINNextLTPro-Bold.38d7c2e7.woff) format("woff");
}

