@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap"); */

* {
  box-sizing: border-box;
}

body {
  background: #000013;
}

@font-face {
  font-family: "BrandRegular";
  src: local("BrandRegular"),
    url("Fonts/DINNextLTPro-Regular.ttf") format("ttf"),
    url("Fonts/DINNextLTPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "BrandMedium";
  src: local("BrandMedium"), url("Fonts/DINNextLTPro-Medium.ttf") format("ttf"),
    url("Fonts/DINNextLTPro-Medium.woff") format("woff");
}

@font-face {
  font-family: "BrandBold";
  src: local("BrandBold"), url("Fonts/DINNextLTPro-Bold.ttf") format("ttf"),
    url("Fonts/DINNextLTPro-Bold.woff") format("woff");
}
